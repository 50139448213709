import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { duration, months } from "moment";
import { wysPrice } from "./web3/getWeb3";
import { useAsyncError } from "react-router-dom";

function Calculator() {
  const pools = [
    {
      name: "WYS",
      duration: 24,
      apr: 3.75,
      multiplier: 2,
      perCent1: 100,
    },
    {
      name: "WYS",
      duration: 36,
      apr: 4.55,
      multiplier: 4,
      perCent1: 100,
    },
    {
      name: "FUSDT",
      duration: 12,
      apr: 16.67,
      multiplier: 3,
      perCent1: 100,
    },
    {
      name: "WYS:ARB",
      duration: 24,
      apr: 2.5,
      multiplier: 2,
      perCent1: 60,
      perCent2: 40,
    },
    {
      name: "WYS:ARB",
      duration: 36,
      apr: 3.85,
      multiplier: 4,
      perCent1: 60,
      perCent2: 40,
    },
    {
      name: "WYS:BNB",
      duration: 24,
      apr: 2.85,
      multiplier: 2,
      perCent1: 50,
      perCent2: 50,
    },
    {
      name: "WYS:BNB",
      duration: 36,
      apr: 4.1,
      multiplier: 4,
      perCent1: 50,
      perCent2: 50,
    },
    {
      name: "WYS:WYZ",
      duration: 24,
      apr: 1.25,
      multiplier: 2,
      perCent1: 50,
      perCent2: 50,
    },
    {
      name: "WYS:WYZ",
      duration: 36,
      apr: 1.5,
      multiplier: 4,
      perCent1: 50,
      perCent2: 50,
    },
    {
      name: "WYS:WYZ",
      duration: 24,
      apr: 1.25,
      multiplier: 2,
      perCent1: 70,
      perCent2: 30,
    },
    {
      name: "WYS:WYZ",
      duration: 36,
      apr: 1.5,
      multiplier: 4,
      perCent1: 70,
      perCent2: 30,
    },
  ];
  
  const [selected, setSelected] = useState(0);
  const [realWysPrice, setRealWysPrice] = useState();
  const [farmAmt, setFarmAmt] = useState(0);
  const [totalWys, setTotalWys] = useState(0);
  const [farmWithdraw, setFarmWithdraw] = useState(0);
  const [userPrice, setUserPrice] = useState(0);
  const [percentReturn, setPercentReturn] = useState(0);
  const [totalInvested, setTotalInvested] = useState();
  const [compoundAmount, setCompoundAmount] = useState();
  const [month, setMonth] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [compoundInterest, setCompoundInterest] = useState();

  useEffect(() => {
    wysPrice().then((res) => {
      setRealWysPrice(Number(res) / 1e18);
    });
  }, []);
  // for getting totalInvested without compounding
  const handleTotalWys = (farm) => {
    const t = Number(farm) / realWysPrice;
    setTotalWys(t);
  };
  // for get withdrawn amount
  const handleWithdraw = () => {
    const apr = Number(selected.apr);
    const duration = Number(selected.duration);
    const yearlyApr = apr * duration;
    const monthlyRate = apr / 100;
    let p = Number(totalWys);
    const returnAmt = (p * yearlyApr) / 100 + p;
    let principal = farmAmt;
    setFarmWithdraw(returnAmt);
    const totalAmount = principal * Math.pow(1 + monthlyRate, duration);
    const wysWithCompoundAmt = totalAmount / realWysPrice;
    if (isChecked) {
      setFarmWithdraw(wysWithCompoundAmt);
    }
    console.log(totalAmount);

    const totalInterest = totalAmount - principal;
    setCompoundInterest(totalInterest);
  };

  const handleReturn = () => {
    let t = Number(totalWys);
    const realPriceInDollar = Number(t) * Number(realWysPrice);
    const returnAmt = Number(userPrice) * Number(farmWithdraw); // dollar
    const r =
      (isChecked ? returnAmt + compoundInterest : returnAmt) /
      Number(realPriceInDollar);
    const finalReturn = Number(r) * 100;
    setPercentReturn(Number(finalReturn));
  };

  useEffect(() => {
    if (selected) {
      handleWithdraw();
    }
  }, [selected, totalWys]);
  useEffect(() => {
    handleReturn();
  }, [userPrice, selected?.duration, totalInvested]);

  useEffect(() => {
    handleReturn();
    handleWithdraw();
  }, [isChecked, totalInvested, userPrice, compoundAmount, farmWithdraw]);

  useEffect(() => {
    console.log(totalInvested, Number(compoundAmount), "totalInvested");
  }, [totalInvested]);

  return (
    <>
      <Header />
      <div
        className="row col-lg-12"
        style={{ background: "url(image/banner.png)", padding: "10px" }}
      >
        <div className="row col-lg-4 cCard" style={{ margin: "90px auto 0px" }}>
          <h5 class="text-gradient " style={{ paddingTop: "30px" }}>
            Return Calculator
          </h5>

          <div className="row col-lg-12 slct">
            <label htmlFor="plan">Pool</label>
            <select
              name="pool"
              id="pool"
              onChange={(e) => {
                pools.find((item) => {
                  if (item.apr == e.target.value) {
                    setSelected(item);
                  }
                });
              }}
            >
              <option value={0} selected>
                Select plan
              </option>
              {pools.map((pool) => (
                <option value={pool.apr}>
                  {`${pool.name} (${pool.duration} month)`}{" "}
                </option>
              ))}
            </select>
          </div>

          <div
            className="row col-lg-12 calculatorForm"
            style={{ marginTop: "10px" }}
          >
            <div className="col-lg-3">
              <label htmlFor="plan">Farm Amount ($)</label>
              <input
                type="text"
                placeholder="Enter amount $"
                value={farmAmt > 0 ? farmAmt : ""}
                onChange={(e) => {
                  setFarmAmt(e.target.value);
                  handleTotalWys(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="plan">WYS Price</label>
              <input
                type="text"
                placeholder="WYS Price"
                value={realWysPrice > 0 ? "$" + realWysPrice?.toFixed(2) : ""}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="plan">Total WYS </label>
              <input
                type="text"
                placeholder="Enter amount"
                value={totalWys > 0 ? totalWys.toFixed(2) + " WYS" : ""}
              />
            </div>
          </div>
          {/* {isChecked && (
            <div
              className="row col-lg-12 calculatorForm"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <div className="col-lg-3">
                <label htmlFor="plan">Compunding amount ($)</label>
                <input
                  type="text"
                  placeholder="Enter amount $"
                  value={compoundAmount > 0 ? compoundAmount : ""}
                  onChange={(e) => {
                    setCompoundAmount(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-3">
                <label htmlFor="plan">Compounding Duration</label>
                <input
                  type="text"
                  placeholder="in month"
                  value={month}
                  onChange={(e) => {
                    setMonth(e.target.value);
                    handleCompunding(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-3">
                <label htmlFor="plan">Total Investment Amount</label>
                <input
                  type="text"
                  placeholder="Enter amount"
                  value={
                    totalInvested > 0 ? totalInvested.toFixed(2) + " WYS" : ""
                  }
                />
              </div>
            </div>
          )} */}

          <div className="row col-lg-12 calculatorForm">
            <div className="col-lg-3">
              <label htmlFor="plan">Farm Withdraw</label>
              <input
                type="text"
                placeholder="Enter amount"
                value={farmWithdraw > 0 ? farmWithdraw.toFixed(2) + " WYS" : ""}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="plan">WYS Price</label>
              <input
                type="text"
                placeholder="Enter amount"
                value={userPrice}
                onChange={(e) => {
                  setUserPrice(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="plan">Result</label>
              <input
                type="text"
                placeholder="Enter amount"
                value={
                  isChecked
                    ? (userPrice * farmWithdraw).toFixed(2) > 0
                      ? "$" +
                        (userPrice * farmWithdraw + compoundInterest).toFixed(2)
                      : 0
                    : (userPrice * farmWithdraw).toFixed(2) > 0
                    ? "$" + (userPrice * farmWithdraw).toFixed(2)
                    : 0
                }
              />
            </div>
          </div>

          <div
            className="row col-lg-12 calculatorForm"
            style={{ marginTop: "20px", marginBottom: "10px" }}
          >
            <div className="col-lg-5" style={{ flexDirection: "row" }}>
              <input
                type="checkbox"
                name="cCheck"
                id="cCheck"
                style={{
                  width: "20px",
                  position: "relative",
                  top: "1px",
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
              />
              <label
                htmlFor="cCheck"
                style={{ paddingLeft: "10px", cursor: "pointer" }}
              >
                With Compounding
              </label>
            </div>
          </div>

          <button className="btn btn-gradient" style={{ marginBottom: "30px" }}>
            {percentReturn > 0 && userPrice > 0 ? percentReturn.toFixed(2) : 0} % return
          </button>
        </div>
      </div>
    </>
  );
}

export default Calculator;
