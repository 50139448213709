import axios from "axios";
import { useAccount } from "wagmi";
import { localhost_api } from "./config";
import { signClaimMessage } from "./getWeb3";
import { toast } from "react-toastify";

export const getRoi = async (address, duration, plan) => {
  try {
    const res = await axios.get(localhost_api + "get-roi", {
      params: {
        user: address,
        duration: duration,
        planId: plan,
      },
    });


    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getreward = async (address) => {
  const sign = await signClaimMessage(address);
  if (sign) {
    const res = await axios.post(localhost_api + "claim-reward", {
      user: address,
      amount: 0,
      signature: sign,
      message: address,
    });
    return res;
  } else {
    toast.error("Signature failed");
    return false;
  }

  // console.log(res, ";;;");
};
export const getUserStake = async (address) => {
  try {
    const res = await axios.post(localhost_api + "all-plans-stake", {
      user: address,
    });

    return res;
  } catch (e) {}
};
